<script lang="ts">
  import {TestScope} from "$app/support/api/test_scope";
  import ClipboardCopy from "$lib/ClipboardCopy.svelte";
  import Data from "$lib/Data.svelte";
  import LightboxPanel from "$lib/LightboxPanel.svelte";
  import Spinner from "$lib/spinkit/Spinner.svelte";

  function ignore() {}

  const test_scope_api = new TestScope();
  const loading = test_scope_api.$loading;
  const error = test_scope_api.$error;
  const test_scope = test_scope_api.test_scope;

  let confirm_create = false;
  let confirm_recreate = false;
  let confirm_delete = false;

  function refresh() {
    test_scope_api.refresh().catch(ignore);
  }

  function cancel() {
    confirm_create = false;
    confirm_recreate = false;
    confirm_delete = false;
  }

  function create(confirmed?: boolean) {
    if (confirmed) {
      test_scope_api.post().catch(ignore);
      confirm_create = false;
    } else {
      confirm_create = true;
    }
  }

  function recreate(confirmed?: boolean) {
    if (confirmed) {
      test_scope_api.post().catch(ignore);
      confirm_recreate = false;
    } else {
      confirm_recreate = true;
    }
  }

  function regenerate_certificates() {
    test_scope_api
      .regenerate_certificates()
      .then(() => test_scope_api.refresh())
      .catch(ignore);
  }

  function delete_scope(confirmed?: boolean) {
    if (confirmed) {
      test_scope_api.delete().catch(ignore);
      confirm_delete = false;
    } else {
      confirm_delete = true;
    }
  }

  refresh();
</script>

<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <h1>Test Scope</h1>
    </div>
  </div>

  <!-- TEST SCOPE -->
  <div class="row">
    <div class="col-xs-12 col-sm-4 stack-1">
      <span class="h2">
        Scope
        {#if $loading}
          <small> <Spinner type="circle-fade" /> </small>
        {/if}
      </span>
    </div>
    <div class="col-xs-12 col-sm-8 text-right stack-1">
      <form class="form-inline form-inline-xs">
        <div class="form-group">
          {#if !$test_scope.api_key}
            <button disabled={$loading} on:click|preventDefault={() => create()} class="btn btn-success">
              <span class="glyphicon glyphicon-plus" />
              <span class="hidden-xs">Create</span>
            </button>
          {:else}
            <button disabled={$loading} on:click|preventDefault={() => recreate()} class="btn btn-warning">
              <span class="glyphicon glyphicon-repeat" />
              <span class="hidden-xs">Recreate</span>
            </button>
          {/if}
          {#if $test_scope.api_key}
            <button
              disabled={$loading}
              on:click|preventDefault={() => regenerate_certificates()}
              class="btn btn-warning"
            >
              <span class="glyphicon glyphicon-repeat" />
              <span class="hidden-xs">Regenerate Certificates</span>
            </button>
          {/if}
          <button disabled={$loading} on:click|preventDefault={() => delete_scope()} class="btn btn-danger">
            <span class="glyphicon glyphicon-trash" />
            <span class="hidden-xs">Delete</span>
          </button>
          <button on:click|preventDefault={refresh} class="btn btn-default">
            <span class="glyphicon glyphicon-refresh" />
            <span class="hidden-xs">Refresh</span>
          </button>
        </div>
      </form>
    </div>
  </div>

  {#if confirm_create}
    <LightboxPanel alert_level="success">
      <svelte:fragment slot="heading">
        <span class="glyphicon glyphicon-question-sign" /> <strong>Create?</strong>
      </svelte:fragment>
      <svelte:fragment>
        <p>Are you sure that want to <strong>create the test scope</strong>?</p>
        <p>If a test scope already exists, it will be <strong>replaced</strong>.</p>
        <div class="text-right">
          <!-- svelte-ignore a11y-missing-attribute -->
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <a class="btn btn-default" on:click|preventDefault={cancel}>Cancel</a>
          <!-- svelte-ignore a11y-missing-attribute -->
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <a class="btn btn-success" on:click|preventDefault={() => create(true)}>Create</a>
        </div>
      </svelte:fragment>
    </LightboxPanel>
  {/if}

  {#if confirm_recreate}
    <LightboxPanel alert_level="warning">
      <svelte:fragment slot="heading">
        <span class="glyphicon glyphicon-question-sign" /> <strong>Recreate?</strong>
      </svelte:fragment>
      <svelte:fragment>
        <p>Are you sure that want to <strong>recreate the test scope</strong>?</p>
        <p>If a test scope already exists, it will be <strong>replaced</strong>.</p>
        <div class="text-right">
          <!-- svelte-ignore a11y-missing-attribute -->
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <a class="btn btn-default" on:click|preventDefault={cancel}>Cancel</a>
          <!-- svelte-ignore a11y-missing-attribute -->
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <a class="btn btn-warning" on:click|preventDefault={() => recreate(true)}>Recreate</a>
        </div>
      </svelte:fragment>
    </LightboxPanel>
  {/if}

  {#if confirm_delete}
    <LightboxPanel alert_level="danger">
      <svelte:fragment slot="heading">
        <span class="glyphicon glyphicon-question-sign" /> <strong>Delete?</strong>
      </svelte:fragment>
      <svelte:fragment>
        <p>Are you sure that want to <strong>delete the test scope</strong>?</p>
        <div class="text-right">
          <!-- svelte-ignore a11y-missing-attribute -->
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <a class="btn btn-default" on:click|preventDefault={cancel}>Cancel</a>
          <!-- svelte-ignore a11y-missing-attribute -->
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <a class="btn btn-danger" on:click|preventDefault={() => delete_scope(true)}>Delete</a>
        </div>
      </svelte:fragment>
    </LightboxPanel>
  {/if}

  {#if !$loading && !$test_scope.api_key}
    <div class="row stack-2">
      <div class="col-xs-12">
        <div class="alert alert-info">A test scope has not been created yet.</div>
      </div>
    </div>
  {/if}

  {#if !$loading && !$error}
    <div class="stack-1">
      <div class="row">
        <div class="col-sm-4 text-right-sm text-muted stack-1">scope_path</div>
        <div class="col-sm-8 stack-1">
          {#if $test_scope.scope_path}
            <Data value={$test_scope.scope_path} />
            <ClipboardCopy data={$test_scope.scope_path} variant="icon-link" />
          {/if}
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4 text-right-sm text-muted stack-1">api_key</div>
        <div class="col-sm-8 stack-1">
          {#if $test_scope.api_key}
            <Data value={$test_scope.api_key} />
            <ClipboardCopy data={$test_scope.api_key} variant="icon-link" />
          {/if}
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4 text-right-sm text-muted stack-1">capture_url</div>
        <div class="col-sm-8 stack-1">
          {#if $test_scope.capture_url}
            <Data value={$test_scope.capture_url} />
            <ClipboardCopy data={$test_scope.capture_url} variant="icon-link" />
          {/if}
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4 text-right-sm text-muted stack-1">nas_identifier</div>
        <div class="col-sm-8 stack-1">
          {#if $test_scope.nas_identifier}
            <Data value={$test_scope.nas_identifier} />
            <ClipboardCopy data={$test_scope.nas_identifier} variant="icon-link" />
          {/if}
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4 text-right-sm text-muted stack-1">certificates</div>
        <div class="col-sm-8 stack-1">
          <a href="/eap-trust">EAP trust chain</a>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 text-right-sm text-muted stack-1" />
        <div class="col-sm-8 stack-1">
          <a href="/eap-credentials">EAP credentials (Password: "password")</a>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 text-right-sm text-muted stack-1" />
        <div class="col-sm-8 stack-1">
          <a href="/radsec-cert">RadSec trust chain</a>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 text-right-sm text-muted stack-1" />
        <div class="col-sm-8 stack-1">
          <a href="/radsec-client">RadSec credentials</a>
        </div>
      </div>
    </div>
  {/if}
</div>
